<template>
    <div ref="appWrapper">
        <router-view v-slot="{ Component }">
            <transition name="vertical" mode="out-in">
                <component :is="Component" />
            </transition>
        </router-view>
    </div>
</template>

<script setup lang="ts">
    import { onMounted, onUnmounted, ref } from 'vue';
    import { RouterView } from 'vue-router';

    const appWrapper = ref<Element | null>(null);
    let resizeOb: ResizeObserver;

    onMounted(() => {
        initWatch();
    });

    onUnmounted(() => {
        if (resizeOb && appWrapper.value) resizeOb.unobserve(appWrapper.value);
    });

    const initWatch = () => {
        if (!appWrapper.value || !parent) return;
        resizeOb = new ResizeObserver((entries) => {
            let rect = entries[0].contentRect;
            let width = (rect.width + 40).toString() + 'px';
            let height = (rect.height + 40).toString() + 'px';

            parent.postMessage(
                JSON.stringify({
                    width,
                    height,
                }),
                '*',
            );
        });
        resizeOb.observe(appWrapper.value);
    };
</script>

<template>
    <div class="tw-relative">
        <svg viewBox="0 0 64 64" style="left: 28%; animation-delay: 0ms; animation-duration: 750ms">
            <circle transform="translate(32,32)" r="6"></circle>
        </svg>
        <svg viewBox="0 0 64 64" style="left: 0; animation-delay: -110ms; animation-duration: 750ms">
            <circle transform="translate(32,32)" r="6"></circle>
        </svg>
        <svg viewBox="0 0 64 64" style="left: -28%; animation-delay: -220ms; animation-duration: 750ms">
            <circle transform="translate(32,32)" r="6"></circle>
        </svg>
    </div>
</template>

<style scoped lang="scss">
    svg {
        transform-origin: center center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: translateZ(0px);
        overflow-clip-margin: content-box;
        overflow: hidden;
        animation: 1s linear 0s infinite normal none running spinner-dots;
    }

    @keyframes spinner-dots {
        0% {
            transform: scale(1, 1);
            opacity: 0.9;
        }
        50% {
            transform: scale(0.4, 0.4);
            opacity: 0.3;
        }
        100% {
            transform: scale(1, 1);
            opacity: 0.9;
        }
    }
</style>

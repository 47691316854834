<template>
    <div>
        <label class="form-label tw-text-gray-400" v-if="label" :for="id">{{ label }}</label>
        <div class="tw-mt-1 tw-flex tw-items-center tw-space-x-3">
            <input
                class="tw-h-10 tw-w-full tw-rounded-3xl tw-border tw-border-gray-300 tw-px-3"
                :value="modelValue"
                @input="onInput"
                type="text"
                :id="id"
                :class="{
                    'tw-border-red-500': error,
                }"
            />
            <ApendayButton
                class="!tw-h-10 !tw-w-10 tw-shrink-0 !tw-rounded-full !tw-border-[var(--color-primary)] !tw-bg-[var(--color-primary)] focus:tw-opacity-70"
                type="submit"
                color="primary"
            >
                <IconSend class="tw-relative tw-top-[1px] tw-right-[1px] tw-w-5 tw-text-white" />
            </ApendayButton>
        </div>
        <field-error class="tw-left-2.5" v-if="error">{{ $t(error) }}</field-error>
    </div>
</template>

<script setup lang="ts">
    import IconSend from '@/components/icons/IconSend.vue';
    import ApendayButton from '@/components/ApendayButton.vue';
    import FieldError from '@/components/live/forms/FieldError.vue';
    import { generateId } from '@/utils/generateId';

    defineProps<{
        label?: string;
        modelValue?: string;
        error?: string;
    }>();

    const emit = defineEmits<{
        (e: 'update:modelValue', str: string): void;
    }>();

    const id = generateId();

    const onInput = ($event: Event) => {
        const target = $event.target as HTMLInputElement;
        emit('update:modelValue', target.value);
    };
</script>

<template>
    <div class="tw-absolute tw-inset-0 tw-flex tw-flex-col">
        <div class="tw-bg-zinc-100 tw-p-0.5 tw-text-center tw-text-xs" v-if="connectionState && connectionState !== 'connected'">
            {{ $t('interaction.messagingView.connectionState.' + lowerCase(connectionState)) }}
        </div>
        <div class="tw-flex tw-grow tw-flex-col">
            <div class="tw-relative tw-grow">
                <div class="custom-scrollbar tw-absolute tw-inset-0 tw-space-y-0.5 tw-overflow-y-auto tw-px-3" ref="conversationRef">
                    <transition-group name="message">
                        <div
                            class="tw-space-y-0.5"
                            v-for="(byUserMessages, indexGroup) in groupedByUserMessages"
                            :class="{ owner: byUserMessages[0].owner }"
                            :key="indexGroup"
                        >
                            <transition-group name="message">
                                <ChatMessageCmp
                                    v-for="(message, indexMessage) in byUserMessages"
                                    :show-nick-name="indexMessage === 0"
                                    :key="message.id"
                                    :message="message"
                                    :first-message="indexMessage === 0"
                                    :last-message="indexMessage + 1 === byUserMessages.length"
                                />
                            </transition-group>
                        </div>
                    </transition-group>
                    <transition name="typing" mode="out-in">
                        <div
                            class="tw-inline-block tw-rounded-xl tw-rounded-tl-none tw-bg-stone-100 tw-px-2 tw-py-1 tw-text-black"
                            v-if="isTyping"
                        >
                            <lds-ellipsis class="tw-h-7 tw-w-9 tw-fill-gray-400" />
                        </div>
                    </transition>
                </div>
            </div>
            <p class="tw-mt-4 tw-px-4 tw-text-right tw-text-xs tw-text-gray-400" v-if="lastMessageTime.length">
                {{ lastMessageTime }}
            </p>
            <form class="tw-px-4 tw-pb-4" v-if="interaction.status !== 'closed' && !conversationClosed" @submit.prevent="onSubmit">
                <InputRichText
                    v-if="!hideInputText"
                    v-model="values['message']"
                    @update:model-value="onInput()"
                    :error="submitCount > 0 ? errors['message'] : undefined"
                />
            </form>
        </div>
    </div>
</template>

<script setup lang="ts">
    import type ApendayChat from '@/classes/apenday-chat';
    import ChatMessageCmp from '@/components/chat/ChatMessage.vue';
    import InputRichText from '@/components/forms/InputRichText.vue';
    import LdsEllipsis from '@/components/LdsEllipsis.vue';
    import { flushInteraction, useInteraction } from '@/composables/interaction';
    import Dayjs from '@/dayjs';
    import { lowerCase } from 'lodash';
    import type { ConnectionState } from 'twilsock';
    import { useField, useForm } from 'vee-validate';
    import { nextTick, onMounted, onUnmounted, ref, toRefs, watch } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { object, string } from 'yup';

    const props = defineProps<{
        interaction: Interaction | Session;
        hideInputText?: boolean;
    }>();

    const emit = defineEmits<{
        (e: 'end'): void;
        (e: 'ready'): void;
        (e: 'tokenExpired'): void;
    }>();

    let client: ApendayChat;
    const { interaction } = toRefs(props);

    const { getInstance } = useInteraction(interaction);
    client = getInstance() as ApendayChat;

    const conversationRef = ref<HTMLInputElement | null>(null);
    const groupedByUserMessages = ref<ChatMessage[][]>([]);
    const { t } = useI18n();
    let intervalId: any;
    const lastMessageTime = ref('');
    const connectionState = ref<ConnectionState>();
    const conversationClosed = ref(false);
    const isTyping = ref(false);

    const validationSchema = object({
        message: string().nullable(),
    });

    watch(groupedByUserMessages, () => updateScroll());

    const { handleSubmit, values, errors, resetForm, submitCount } = useForm({
        validationSchema,
        initialValues: {
            message: '',
        },
    });

    useField('message');

    const onSubmit = handleSubmit(async (value: { message: string }) => {
        if (value.message.length) {
            resetForm();
            client.addMessage(value);
            updateScroll();
        }
    });

    const updateScroll = () => {
        nextTick(() => {
            conversationRef.value?.scrollTo({
                left: 0,
                top: 60000,
            });
        });
    };

    const updateMessagesHandle = ({ messages }: { messages: ChatMessage[][] }) => {
        groupedByUserMessages.value = messages;
        client.setAllMessagesRead();
    };

    const readyHandler = () => {
        emit('ready');
        handleTimer();
        updateScroll();
    };

    const handleTimer = () => {
        let date = '';
        if (groupedByUserMessages.value.length) {
            const last = [...groupedByUserMessages.value].pop();
            if (last?.length) {
                date = Dayjs([...last].pop()?.addDate).fromNow();
            }
            lastMessageTime.value = date ? t('interaction.messagingView.lastMessage', { value: date }) : '';
        }
    };

    const connectionStateChangedHandler = (state: ConnectionState) => (connectionState.value = state);
    const conversationClosedHandler = () => {
        conversationClosed.value = true;
        emit('end');
    };

    const onInput = () => client.typing();

    const typingStartedHandler = () => {
        isTyping.value = true;
        updateScroll();
    };

    const typingEndedHandler = () => {
        isTyping.value = false;
        updateScroll();
    };

    const tokenExpiredHandler = () => {
        emit('tokenExpired');
    };

    onMounted(() => {
        client.onWithReplay('groupedByUserMessagesUpdated', updateMessagesHandle);
        client.onWithReplay('ready', readyHandler);
        client.onWithReplay('connectionStateChanged', connectionStateChangedHandler);
        client.onWithReplay('typingStarted', typingStartedHandler);
        client.onWithReplay('typingEnded', typingEndedHandler);
        client.onWithReplay('conversationClosed', conversationClosedHandler);
        client.onWithReplay('tokenExpired', tokenExpiredHandler);
        intervalId = setInterval(() => handleTimer(), 5000);
    });

    onUnmounted(() => {
        client.off('groupedByUserMessagesUpdated', updateMessagesHandle);
        client.off('ready', readyHandler);
        client.off('connectionStateChanged', connectionStateChangedHandler);
        client.off('typingStarted', typingStartedHandler);
        client.off('typingEnded', typingEndedHandler);
        client.off('conversationClosed', conversationClosedHandler);
        client.off('tokenExpired', tokenExpiredHandler);
        if (intervalId) clearInterval(intervalId);
    });
</script>

<template>
    <div
        class="tw-flex tw-cursor-pointer tw-items-center tw-justify-between tw-bg-[var(--color-primary)] tw-py-2 tw-pl-5 tw-pr-4 tw-text-white"
        @click="reduce()"
    >
        <div class="tw-pr-10 tw-text-base tw-font-normal tw-leading-5 tw-tracking-wide">
            <slot />
        </div>
        <div class="tw-flex tw-h-full tw-flex-col tw-justify-between">
            <button class="tw-p-2 hover:tw-opacity-70" type="button" @click.stop="close()">
                <icon-close class="tw-w-3" />
            </button>
            <button class="tw-p-2 hover:tw-opacity-70" type="button">
                <component class="tw-w-3" :is="expanded ? IconChevronDown : IconChevronUp" />
            </button>
        </div>
    </div>
</template>
<script setup lang="ts">
    import IconChevronDown from '@/components/icons/IconChevronDown.vue';
    import IconChevronUp from '@/components/icons/IconChevronUp.vue';
    import IconClose from '@/components/icons/IconClose.vue';
    import { useWidgetStore } from '@/store/widget';
    import { storeToRefs } from 'pinia';
    import { useRoute, useRouter } from 'vue-router';

    const widgetStore = useWidgetStore();
    const { widget, expanded } = storeToRefs(widgetStore);

    const router = useRouter();
    const route = useRoute();

    const close = () => {
        router.replace({ name: 'hidden' });
    };

    const reduce = () => {
        if (widget.value?.starting_display_mode === 'button') {
            widgetStore.updateBackRouteName(route.name);
            router.replace({ name: 'minified' });
        } else {
            widgetStore.updateExpanded(!expanded.value);
        }
    };
</script>

<template>
    <div class="tw-relative tw-w-[500px] tw-p-4">
        <div class="tw-bg-red-500 tw-p-2 tw-text-white" v-if="errors.length">
            <p v-for="error in errors" :key="error">{{ error }}</p>
        </div>
        <OverlayLoader v-if="!isReady && errors.length === 0" />
        <div class="tw-relative tw-w-full tw-pb-[67%]">
            <div class="tw-absolute tw-inset-0">
                <video-view v-if="interaction && isReady" @end="end" :interaction="interaction" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import type ApendayVideo from '@/classes/apenday-video';
    import OverlayLoader from '@/components/live/OverlayLoader.vue';
    import VideoView from '@/components/video/VideoView.vue';
    import { useInteraction } from '@/composables/interaction';
    import { useInteractionStore } from '@/store/interaction';
    import { storeToRefs } from 'pinia';
    import { onMounted, onUnmounted, ref } from 'vue';
    import { useRouter } from 'vue-router';
    import { useWidgetStore } from '@/store/widget';

    const interactionStore = useInteractionStore();
    const { interaction } = storeToRefs(interactionStore);
    const widgetStore = useWidgetStore();
    const { widget } = storeToRefs(widgetStore);
    const isReady = ref(false);
    const errors = ref<any>([]);
    let client: ApendayVideo;
    const router = useRouter();

    // @ts-ignore
    const { getInstance } = useInteraction(interaction);
    // @ts-ignore
    client = getInstance();

    const readyHandler = () => {
        isReady.value = true;
    };

    const errorHandler = (error: any) => {
        if (error === 'Room not found') {
            router.replace({ name: 'video-end' });
        }
        if (error) {
            errors.value.push(error);
        } else {
            errors.value = [];
        }
    };

    function end() {
        if (widget.value.enabled_satisfaction_survey) {
            router.replace({ name: 'video-satisfaction' });
        } else {
            router.replace({ name: 'video-end' });
        }
    }

    onMounted(async () => {
        client.onWithReplay('ready', readyHandler);
        client.onWithReplay('error', errorHandler);
    });

    onUnmounted(() => {
        client.off('ready', readyHandler);
        client.off('error', errorHandler);
    });
</script>

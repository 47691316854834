<template>
    <div class="tw-relative tw-h-10 tw-w-10">
        <badge-status class="tw-absolute -tw-right-0.5 -tw-top-0.5" v-if="isAvailableConversation || isAvailableVideo" />
        <div class="tw-overflow-hidden tw-rounded-full tw-bg-gray-300">
            <img src="/assets/images/widget-avatar.jpg" alt="user-avatar" />
        </div>
    </div>
</template>
<script setup lang="ts">
    import BadgeStatus from '@/components/live/BadgeStatus.vue';
    import { useWidgetStore } from '@/store/widget';
    import { storeToRefs } from 'pinia';
    const widgetStore = useWidgetStore();
    const { isAvailableConversation, isAvailableVideo } = storeToRefs(widgetStore);
</script>

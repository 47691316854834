<template>
    <icon-all-chans :class="classes" />
</template>

<script setup lang="ts">
    import IconAllChans from '@/components/icons/IconAllChans.vue';
    import { computed, toRefs } from 'vue';

    const props = withDefaults(
        defineProps<{
            size?: 'small' | 'normal';
            color?: 'light' | 'dark';
        }>(),
        {
            size: 'normal',
            color: 'light',
        },
    );
    const { size, color } = toRefs(props);

    const classes = computed(() => {
        let str = '';
        str += size.value === 'small' ? 'tw-w-7 ' : 'tw-w-9 ';
        str += color.value === 'light' ? 'tw-text-white' : 'tw-text-black';
        return str;
    });
</script>

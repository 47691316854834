<template>
    <div class="tw-relative">
        <OverlayLoader v-if="loading.isLoading('interaction.create')" />
        <div class="tw-relative tw-max-h-[80vh] tw-w-80 tw-space-y-3 tw-overflow-y-auto tw-p-4">
            <WidgetSummary>
                {{ t('widget.summaryForm') }}
            </WidgetSummary>
            <div class="tw-text-xs tw-text-mineShaft/60" v-if="areaName && privatePolicyLink">
                <p v-html="t('widget.live_privacy_policy', [areaName, privatePolicyLink])" />
            </div>
            <form
                @submit.prevent="
                    triedSubmission = true;
                    onSubmit();
                "
            >
                <FormDynamicFields v-model="values['dynamic_field_values']" only-one-column ref="dynamicFieldsForm" :item="widget" />
                <ApendayButton
                    class="tw-mt-3 tw-w-full !tw-border-[var(--color-primary)] !tw-bg-[var(--color-primary)] focus:tw-opacity-70"
                    v-if="route.meta.channel === 'video'"
                    type="submit"
                    color="primary"
                >
                    {{ t('common.launch') }}
                    <icon-chevron-right class="tw-w-6 tw-text-white" />
                </ApendayButton>

                <InputRichText
                    class="tw-mt-5"
                    v-if="route.meta.channel === 'messaging'"
                    v-model="values['message']"
                    :error="submitCount > 0 ? errors['message'] : undefined"
                    :label="t('widget.form.fields.ask')"
                />
                <input type="submit" hidden />
            </form>
        </div>
    </div>
</template>

<script setup lang="ts">
    import IconChevronRight from '@/components/icons/IconChevronRight.vue';
    import ApendayButton from '@/components/ApendayButton.vue';
    import InputRichText from '@/components/live/forms/InputRichText.vue';
    import OverlayLoader from '@/components/live/OverlayLoader.vue';
    import WidgetSummary from '@/components/live/WidgetSummary.vue';
    import { useLoader } from '@/composables/wait';
    import type { CreateInteractionPayload } from '@/store/interaction';
    import { useInteractionStore } from '@/store/interaction';
    import { useField, useForm } from 'vee-validate';
    import { useRoute, useRouter } from 'vue-router';
    import { lazy, object, string } from 'yup';
    import { useWidgetStore } from '@/store/widget';
    import { storeToRefs } from 'pinia';
    import FormDynamicFields from '@/components/forms/FormDynamicFields.vue';
    import { computed, ref } from 'vue';
    import { toast } from '@/composables/toast';
    import { useI18n } from 'vue-i18n';

    const widgetStore = useWidgetStore();
    const { widget: _widget } = storeToRefs(widgetStore);
    const widget = computed<Widget>(() => {
        _widget.value.dynamic_fields.map((field) => {
            field.display_column = 'full';
            return field;
        });
        return _widget.value;
    });
    const areaName = computed(() => _widget.value.area.name);
    const privatePolicyLink = computed(() => {
        return _widget.value.area?.live_privacy_policy_file?.content_url ?? _widget.value.area?.live_privacy_policy_url;
    });
    const route = useRoute();
    const router = useRouter();
    const interactionStore = useInteractionStore();
    const { loading } = useLoader();
    const dynamicFieldsForm = ref<InstanceType<typeof FormDynamicFields> | null>(null);
    const triedSubmission = ref(false);
    const { t } = useI18n();

    const validationSchema = object({
        message: route.meta.channel === 'messaging' ? string().required() : string().nullable(),
        dynamic_field_values: lazy(() => {
            return object()
                .nullable()
                .test(
                    'inviteFieldValues',
                    () => `validation.inviteFieldValues`,
                    async () => {
                        if (dynamicFieldsForm.value && triedSubmission.value) return dynamicFieldsForm.value.validate();
                        else return true;
                    },
                );
        }),
    });

    const { handleSubmit, errors, values, submitCount } = useForm<CreateInteractionPayload>({
        validationSchema,
        initialValues: {
            channel: route.meta.channel ?? 'messaging',
            message: '',
        },
    });

    useField('message');

    const onSubmit = handleSubmit(async (values: CreateInteractionPayload) => {
        if (loading.isLoading('interaction.create')) return;
        try {
            await interactionStore.create(values, route.params.id);
            router.replace({
                name: `${route.meta.channel}-talk`,
            });
            loading.stop('interaction.create');
        } catch (e) {
            if (e.response?.status === 503) {
                localStorage?.setItem('show-unavailability-msg', 'true');
                window.location = '/' + route.params.id;
            } else {
                toast({
                    text: e.customErrorMsg ?? e.toString(),
                    type: 'error',
                });
                loading.stop('interaction.create');
            }
        }
    });
</script>

<template>
    <div class="tw-text-sm">
        <p
            class="tw-mb-1 tw-mt-4 tw-font-semibold"
            v-if="showNickName && !isService"
            :class="{
                'tw-text-right': message.owner,
            }"
        >
            {{ message.owner ? $t('interaction.messagingView.ownerLabel') : message.nickname }}
        </p>
        <div
            class="tw-flex tw-items-center tw-space-x-2"
            v-if="message.body"
            @click="showTime = !showTime"
            :class="{
                'tw-ml-auto tw-w-5/6 tw-justify-end': message.owner && !isService,
                'tw-mr-auto tw-w-5/6 tw-justify-start': !message.owner && !isService,
                'tw-mx-auto tw-justify-center': isService,
            }"
        >
            <div class="tw-text-xs" v-if="message.owner">
                <p v-if="!message.sendSuccess">
                    <lds-ellipsis class="tw-h-7 tw-w-9 tw-fill-secondary" />
                </p>
            </div>
            <div
                class="tw-px-4 tw-py-2"
                :class="{
                    'tw-rounded-l-xl tw-bg-blue-400 tw-text-white': message.owner && !isService,
                    'tw-rounded-r-xl tw-bg-stone-100 tw-text-black': !message.owner && !isService,
                    'tw-rounded-tr-xl': message.owner && firstMessage && !isService,
                    'tw-rounded-br-xl': message.owner && lastMessage && !isService,
                    'tw-rounded-tl-xl': !message.owner && firstMessage && !isService,
                    'tw-rounded-bl-xl': !message.owner && lastMessage && !isService,
                    'tw-text-center tw-text-xs tw-text-gray-500': isService,
                }"
            >
                <p v-if="isService && te(`interaction.messagingView.serviceMessage.${message.body}`)">
                    {{ t(`interaction.messagingView.serviceMessage.${message.body}`) }}
                </p>
                <p v-else>{{ message.body }}</p>
            </div>
        </div>
        <transition name="chat-time">
            <div
                class="tw-my-1 tw-flex tw-items-center tw-space-x-1 tw-text-xs tw-text-gray-400"
                v-if="showTime && message.addDate"
                :class="{
                    'tw-justify-end': message.owner,
                }"
            >
                <icon-clock class="tw-w-3" />
                <span>{{ postDate }}</span>
            </div>
        </transition>
    </div>
</template>
<script setup lang="ts">
    import IconClock from '@/components/icons/IconClock.vue';
    import LdsEllipsis from '@/components/LdsEllipsis.vue';
    import Dayjs from '@/dayjs';
    import { computed, onMounted, onUnmounted, ref, toRefs } from 'vue';
    import { useI18n } from 'vue-i18n';

    const postDate = ref();
    let intervalId: any;
    const showTime = ref(false);
    const { te, t } = useI18n();

    const props = defineProps<{
        message: ChatMessage;
        showNickName?: boolean;
        firstMessage?: boolean;
        lastMessage?: boolean;
    }>();
    const { message } = toRefs(props);
    const isService = computed(() => !message.value.nickname);

    const handle = () => {
        postDate.value = Dayjs(message.value.addDate).fromNow();
    };

    onMounted(() => {
        intervalId = setInterval(() => handle(), 5000);
        handle();
    });

    onUnmounted(() => {
        if (intervalId) clearInterval(intervalId);
    });
</script>

import { isArray, isBoolean, isObject } from 'lodash';

export function stringifyDynamicFieldValue(value): string {
    if (isObject(value) && value['@context'] === '/api/contexts/MediaObject') {
        return value['@id'];
    } else if (isArray(value)) {
        return JSON.stringify(value);
    } else if (isBoolean(value)) {
        return value ? '1' : '0';
    } else if (typeof value === 'string') {
        return value;
    }
    return '';
}

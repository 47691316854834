<template>
    <div class="tw-overflow-hidden tw-rounded-xl tw-bg-white tw-shadow-xl">
        <box-header is-open>
            <div class="tw-flex tw-items-center">
                <icon-video class="tw-w-7" />
                <span class="tw-ml-3">{{ $t('widget.header.video') }}</span>
            </div>
        </box-header>
        <router-view v-if="expanded" />
    </div>
</template>
<script setup lang="ts">
    import IconVideo from '@/components/icons/IconVideo.vue';
    import BoxHeader from '@/components/live/BoxHeader.vue';
    import { useWidgetStore } from '@/store/widget';
    import { storeToRefs } from 'pinia';

    const widgetStore = useWidgetStore();
    const { expanded } = storeToRefs(widgetStore);
</script>

<template>
    <div>
        <Error :error="error">
            <template v-for="(section, index) in computedFields" :key="index">
                <div class="tw-mt-3 tw-mb-2 tw-py-2" v-if="section.title" style="border-bottom: 1px solid var(--color-secondary)">
                    <span class="typo-2 text-secondary">{{ section.title }}</span>
                </div>
                <template v-for="(subsection, index) in section.fields" :key="index">
                    <div class="row gx-3">
                        <div class="col-12">
                            <FormFieldSet class="!tw-space-y-3">
                                <template v-for="(field, index) in subsection.fields" :key="index">
                                    <FormDynamicField
                                        v-if="field.display_column === 'full'"
                                        v-model="values[getFieldId(field)]"
                                        :error="errors[getFieldId(field)]"
                                        :field="field"
                                        :item="item"
                                    />
                                </template>
                            </FormFieldSet>
                        </div>
                        <div class="col-md-6">
                            <FormFieldSet class="!tw-space-y-3">
                                <template v-for="(field, index) in subsection.fields" :key="index">
                                    <FormDynamicField
                                        v-if="field.display_column === 'left'"
                                        v-model="values[getFieldId(field)]"
                                        :error="errors[getFieldId(field)]"
                                        :field="field"
                                        :item="item"
                                    />
                                </template>
                            </FormFieldSet>
                        </div>
                        <div class="col-md-6">
                            <FormFieldSet class="!tw-space-y-3">
                                <template v-for="(field, index) in subsection.fields" :key="index">
                                    <FormDynamicField
                                        v-if="field.display_column === 'right'"
                                        v-model="values[getFieldId(field)]"
                                        :error="errors[getFieldId(field)]"
                                        :field="field"
                                        :item="item"
                                    />
                                </template>
                            </FormFieldSet>
                        </div>
                    </div>
                </template>
            </template>
        </Error>
    </div>
</template>

<script lang="ts" setup>
    import Error from '@/components/forms/Error.vue';
    import { getIdFromIri } from '@/utils/getIdFromIri';
    import { removeEmptyKeys } from '@/utils/removeEmptyKeys';
    import { forEach } from 'lodash';
    import { useField, useForm } from 'vee-validate';
    import { computed, reactive, ref, toRefs, watch } from 'vue';
    import FormDynamicField from '@/components/forms/FormDynamicField.vue';
    import FormFieldSet from '@/components/forms/FormFieldSet.vue';

    type FieldValues = { [Key: string]: string };

    const props = defineProps<{
        /**
         * The item which contains the dynamic fields
         */
        item: { id: string; dynamic_fields: DynamicField[] };
        /**
         * Enable required validation rule on fields
         */
        fieldRequiredRuleDisabled?: boolean;
        /**
         * Use query string parameter as field id
         */
        useQueryParams?: boolean;
        /**
         * Display only one column
         */
        onlyOneColumn?: boolean;
        /**
         * Error message of the entire form
         */
        error?: any;
    }>();

    const emit = defineEmits<{
        (e: 'update:modelValue', val: FieldValues): void;
    }>();

    const { item, fieldRequiredRuleDisabled, useQueryParams, onlyOneColumn } = toRefs(props);
    const validationRules = reactive({});
    const triedSubmission = ref(false);

    const dynamicFields = computed(() => {
        return item.value.dynamic_fields;
    });

    function getFieldId(field: DynamicField) {
        return useQueryParams.value ? field.query_string_parameter : field.id ?? getIdFromIri(field['@id']);
    }

    const getSections = (subFields: DynamicField[]) => {
        let section = 0;
        const array = [];

        forEach(subFields, (field) => {
            // test array[section] prevent first empty entry
            if (field.type === 'section_separator' && array[section] && !onlyOneColumn.value) {
                section += 1;
            }
            if (!array[section]) array[section] = { fields: [] };
            if (field.type === 'section_separator') {
                array[section].title = field.label;
                array[section].type = 'section';
            } else {
                array[section].fields.push(field);
            }
        });

        return array;
    };

    const getSubSections = (sections) => {
        forEach(sections, (section) => {
            let subSection = 0;
            const array = [];

            forEach(section.fields, (field) => {
                // test array[subSection] prevent first empty entry
                if (field.display_column === 'full' && array[subSection] && !onlyOneColumn.value) {
                    subSection += 1;
                }
                if (!array[subSection]) array[subSection] = { fields: [] };
                array[subSection].fields.push(field);
            });
            section.fields = array;
        });

        return sections;
    };

    const computedFields = computed(() => {
        return getSubSections(getSections(dynamicFields.value));
    });

    const getValidationRules = (item: DynamicField) => {
        const validation: Record<string, string | boolean> = {};
        if (item.type === 'mention') return validation;
        if (item.required && !fieldRequiredRuleDisabled.value) validation.required = true;
        if (item.type === 'file') return validation;
        if (item.type === 'email') validation.email = true;

        if (item.constraints.length) {
            item.constraints.forEach((constraint) => {
                validation[constraint.type] = constraint.value ?? true;
            });
        }

        return validation;
    };

    const { handleSubmit, values, meta, errors: vErrors } = useForm();

    const errors = computed(() => {
        return triedSubmission.value ? vErrors.value : {};
    });

    dynamicFields.value.forEach((dynamicField) => {
        const id = getFieldId(dynamicField);
        validationRules[id] = getValidationRules(dynamicField);
        const rules = ref(validationRules[id]);
        useField(id, rules, {
            label: dynamicField.type,
        });
    });

    const validate = async (): Promise<boolean> => {
        triedSubmission.value = true;
        await handleSubmit((values) => values)();
        return meta.value.valid;
    };

    watch(values, (val) => emit('update:modelValue', removeEmptyKeys(val)), {
        deep: true,
    });

    defineExpose({
        validate,
    });
</script>

<template>
    <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path d="M6 3V6L7.5 7.5" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
</template>

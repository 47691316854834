<template>
    <FormField>
        <FormLabel v-if="field.label && !['checkbox', 'mention'].includes(field.type)">{{ field.label }}</FormLabel>
        <Component
            v-bind="$attrs"
            :required="field.required"
            :nullable="!field.required"
            :is="formComponents[field.type]"
            :options="field.options"
            :placeholder="field.label"
            values-format="YYYY-MM-DD"
            label-format="LL"
            :multiple="field.type === 'checkboxes'"
            :class="{ 'tw-mt-4': field.type === 'checkbox' }"
            :allow-other-option="field.allow_other_option"
            :field-id="field.id ?? getIdFromIri(field['@id'])"
            :item="item"
            :label="field.label"
        />
        <FormHelper v-if="field.helper_text" :content="field.helper_text" />
    </FormField>
</template>
<script setup lang="ts">
    import { getIdFromIri } from '@/utils/getIdFromIri';
    import { defineAsyncComponent } from 'vue';
    import type { Component } from 'vue';
    import FormLabel from '@/components/forms/FormLabel.vue';
    import FormField from '@/components/forms/FormField.vue';
    import FormHelper from '@/components/forms/FormHelper.vue';

    defineProps<{
        field: DynamicField;
        item?: { id: string };
    }>();

    const formComponents: Partial<{ [K in DynamicFieldType]: Component }> = {
        text: defineAsyncComponent(() => import('@/components/forms/InputText.vue')),
        email: defineAsyncComponent(() => import('@/components/forms/InputText.vue')),
        phone: defineAsyncComponent(() => import('@/components/forms/InputText.vue')),
        textarea: defineAsyncComponent(() => import('@/components/forms/InputTextArea.vue')),
        checkbox: defineAsyncComponent(() => import('@/components/forms/InputCheckbox.vue')),
        checkboxes: defineAsyncComponent(() => import('@/components/forms/InputSelect.vue')),
        radios: defineAsyncComponent(() => import('@/components/forms/InputRadio.vue')),
        select: defineAsyncComponent(() => import('@/components/forms/InputSelect.vue')),
        mention: defineAsyncComponent(() => import('@/components/forms/InputMention.vue')),
        date: defineAsyncComponent(() => import('@/components/forms/InputDate.vue')),
        // Meeting Only
        datasource_multiselect: defineAsyncComponent(() => import('@/components/forms/InputDatasource.vue')),
        file: defineAsyncComponent(() => import('@/components/forms/InputFile.vue')),
        hidden: defineAsyncComponent(() => import('@/components/forms/InputText.vue')),
    };
</script>

<template>
    <div
        :class="{
            'tw-text-white': color === 'dark',
            'tw-text-black': color === 'light',
        }"
    >
        <button
            class="tw-flex tw-items-center tw-rounded-md tw-border tw-border-mineShaft tw-px-3 tw-py-1.5 hover:tw-opacity-50"
            type="button"
            :class="{
                'tw-w-full tw-justify-between tw-pr-2': showLabel,
                'tw-justify-center': !showLabel,
                'tw-bg-mineShaft': color === 'dark',
            }"
        >
            <span class="tw-font-normal tw-uppercase tw-tracking-wide" v-if="showLabel">{{ $t(`widget.joinBtnLabels.${channel}`) }}</span>
            <span class="tw-flex tw-h-6 tw-w-6 tw-items-center" :class="{ 'tw-justify-center': !showLabel }">
                <component :is="icons[channel].tpl" :class="icons[channel].size" />
            </span>
        </button>
        <p class="tw-mt-2 tw-mb-4 tw-text-[.65rem] tw-leading-4 tw-tracking-wide" v-if="showHelper && showLabel">
            <slot name="description">{{ $t(`widget.joinBtnHelpers.${channel}`) }}</slot>
        </p>
    </div>
</template>
<script setup lang="ts">
    import IconCalendar from '@/components/icons/IconCalendar.vue';
    import IconMessageCircle from '@/components/icons/IconMessageCircle.vue';
    import IconVideo from '@/components/icons/IconVideo.vue';

    withDefaults(
        defineProps<{
            channel?: 'meeting' | ChannelType;
            color?: 'light' | 'dark';
            showHelper?: boolean;
            showLabel?: boolean;
        }>(),
        {
            channel: 'meeting',
            color: 'light',
        },
    );

    const icons = {
        meeting: {
            tpl: IconCalendar,
            size: 'tw-w-5',
        },
        messaging: {
            tpl: IconMessageCircle,
            size: 'tw-w-5',
        },
        video: {
            tpl: IconVideo,
            size: 'tw-w-6',
        },
        audio: {
            tpl: IconVideo,
            size: 'tw-w-6',
        },
    };
</script>

<template>
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="2 2 20 21">
        <path
            clip-rule="evenodd"
            d="M3 6a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V6Z"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path d="M16 2v4M8 2v4M3 10h18" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
</template>

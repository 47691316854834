import { computed, ref } from 'vue';

const loaders = ref<string[]>([]);

export const useLoader = () => {
    const start = (loaderId: LoaderId) => {
        loaders.value = push(loaders.value)(loaderId);
        return () => stop(loaderId);
    };

    const stop = (loaderId: LoaderId) => {
        loaders.value = pop(loaders.value)(loaderId);
    };

    const isLoading = (loaderId: LoaderId): boolean => {
        const isLoading = computed(() => contains(loaders.value)(loaderId));
        return isLoading.value;
    };

    return {
        loading: {
            start,
            stop,
            isLoading,
        },
    };
};

type LoaderId = string;
const DEFAULT_LOADER: LoaderId = '';

export const uniq = (array: LoaderId[]) => {
    return array.filter((el, index, arr) => index === arr.indexOf(el));
};

export const contains = (array: Array<LoaderId>) => (predicate: LoaderId) => {
    return array.includes(predicate);
};

export const hasItems = (array: LoaderId[]) => array.length > 0;

export const push =
    (array: LoaderId[]) =>
    (item: LoaderId = DEFAULT_LOADER) =>
        uniq([...array, item]);

export const pop =
    (array: LoaderId[]) =>
    (item: LoaderId = DEFAULT_LOADER) =>
        array.filter((_item) => _item !== item);

import axios from '@/axios';
import { useLoader } from '@/composables/wait';
import { stringifyDynamicFieldValue } from '@/utils/stringifyDynamicFieldValue';
import { castArray, forEach } from 'lodash';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export type CreateInteractionPayload = {
    channel: ChannelType;
    message?: string;
    dynamic_field_values?: DynamicFieldValue[];
};

export const useInteractionStore = defineStore('interaction', () => {
    const { loading } = useLoader();
    const interaction = ref<Interaction>();

    // Mutators
    const updateInteraction = (item: Interaction) => {
        interaction.value = item;
    };

    // Actions
    const create = async (payload: CreateInteractionPayload, _id: string | string[]): Promise<Interaction> => {
        const id = castArray(_id);
        loading.start('interaction.create');
        const formatedDynamicFieldValues = [];
        forEach(payload['dynamic_field_values'], (value, key) => {
            formatedDynamicFieldValues.push({
                dynamic_field: { id: key },
                value: stringifyDynamicFieldValue(value),
            });
        });
        payload['dynamic_field_values'] = formatedDynamicFieldValues;

        const interaction = await axios.request<any, Interaction>({
            method: 'post',
            url: `api/live/${id}/start-interaction`,
            data: payload,
        });
        updateInteraction(interaction);
        // localStorage.setItem('interaction', JSON.stringify(interaction));
        loading.stop('interaction.create');

        return interaction;
    };

    return {
        interaction,
        create,
        updateInteraction,
    };
});

<template>
    <div class="tw-w-60 tw-overflow-hidden tw-rounded-xl tw-bg-white tw-shadow-xl">
        <box-header is-open>
            <p>{{ widget?.title }}</p>
        </box-header>
        <div class="tw-p-4" v-if="expanded">
            <widget-summary class="tw-mb-3" v-if="summaryText">
                {{ summaryText }}
            </widget-summary>
            <div
                :class="{
                    'tw-flex tw-justify-between': !showLabel,
                    'tw-space-y-2.5': showLabel,
                }"
            >
                <join-button
                    v-if="fallbackLink"
                    @click="open(fallbackLink)"
                    channel="meeting"
                    :show-label="showLabel"
                    :show-helper="showHelpers"
                >
                    <template #description>{{ widget?.fallback_description }}</template>
                </join-button>
                <join-button
                    v-if="isAvailableVideo"
                    @click="$router.replace({ name: 'video-form' })"
                    channel="video"
                    :show-label="showLabel"
                    :show-helper="showHelpers"
                >
                    <template #description>{{ widget?.channel_video_description }}</template>
                </join-button>
                <join-button
                    v-if="isAvailableConversation"
                    @click="$router.replace({ name: 'messaging-form' })"
                    channel="messaging"
                    :show-label="showLabel"
                    :show-helper="showHelpers"
                >
                    <template #description>{{ widget?.channel_messaging_description }}</template>
                </join-button>
            </div>
            <div class="tw-mt-3 tw-flex tw-justify-center" v-if="!isAvailable && widget?.another_availability_period_today">
                <ApendayButton
                    class="!tw-border-[var(--color-primary)] !tw-bg-[var(--color-primary)]"
                    type="button"
                    color="primary"
                    @click="retry"
                    >{{ t('common.retry') }}
                </ApendayButton>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import BoxHeader from '@/components/live/BoxHeader.vue';
    import JoinButton from '@/components/live/JoinButton.vue';
    import WidgetSummary from '@/components/live/WidgetSummary.vue';
    import { useWidgetStore } from '@/store/widget';
    import { storeToRefs } from 'pinia';
    import { computed } from 'vue';
    import ApendayButton from '@/components/ApendayButton.vue';
    import { useI18n } from 'vue-i18n';
    import { useRoute } from 'vue-router';

    const widgetStore = useWidgetStore();
    const { widget, isAvailableConversation, isAvailableVideo, fallbackLink, isAvailable, summaryText, expanded } =
        storeToRefs(widgetStore);
    const showLabel = computed(() => widget.value?.content_display_mode !== 'compact');
    const showHelpers = computed(() => widget.value?.content_display_mode === 'rich');
    const { t } = useI18n();
    const route = useRoute();

    const open = (url) => {
        if (parent) {
            parent.postMessage(
                JSON.stringify({
                    url,
                }),
                '*',
            );
        }
    };

    const retry = () => {
        window.location = '/' + route.params.id;
    };
</script>

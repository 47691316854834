import { getEnv } from '@/env/env';
import router from '@/router/live';
import { BrowserTracing } from '@sentry/tracing';
import { init, Replay, vueRouterInstrumentation } from '@sentry/vue';
import type { App } from 'vue';

export const initSentry = (app: App) => {
    init({
        app,
        enabled: import.meta.env.PROD,
        dsn: 'https://212921bc4cdb4a9190ee8354f8345db9@o1290556.ingest.sentry.io/4504775487389696',
        release: getEnv().releaseName,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: vueRouterInstrumentation(router),
                tracePropagationTargets: ['live.apenday.com', 'preprod.live.apenday.com', 'int.live.apenday.com', /^\//],
            }),
            new Replay({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        tracesSampleRate: 0.3,
        environment: getEnv().envName,
    });
};

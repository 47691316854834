<template>
    <svg
        class="feather feather-send"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        viewBox="0 0 24 24"
    >
        <path d="M22 2 11 13M22 2l-7 20-4-9-9-4 20-7z" />
    </svg>
</template>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 23">
        <path
            class="st1"
            fill="none"
            color="currentColor"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12.2 8.1h3.2V3.4c0-.7-.7-1.4-1.5-1.4H3.7c-.8 0-1.5.6-1.5 1.4v10.8l2.9-2.7h5.7v-2c0-.8.6-1.4 1.4-1.4zM10.8 11.5v4.7c0 .7.6 1.4 1.4 1.4h8.1l2.7 2.7V9.5c0-.7-.6-1.4-1.4-1.4h-6.2"
        />
    </svg>
</template>

<template>
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 4 24 16">
        <path
            clip-rule="evenodd"
            d="m23 7-7 5 7 5V7v0ZM1 7a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V7Z"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
